export default [
    //项目列表页面
    {
        path: "/project-list",
        name: "index-project-list",
        component: () => import("@/views/front/common/project-list/Map.vue"),
    },
    //项目详情页面
    {
        path: "/:project_id/project-detail",
        name: "index-project-detail",
        component: () => import("@/views/front/common/project-detail/Detail.vue"),
    },
    //项目详情页面
    {
        path: "/:project_id/project-vr",
        name: "index-project-vr",
        component: () => import("@/views/front/common/project-vr/ProjectVr.vue"),
    },
    //台账列表页面
    {
        path: "/:project_id/ledger-list",
        name: "index-ledger-list",
        component: () => import("@/views/front/common/ledger-list/LedgerList.vue"),
    },
    //报修列表页面
    {
        path: "/:project_id/repair-list",
        name: "index-repair-list",
        component: () => import("@/views/front/common/repair-list/RepairList.vue"),
    },
    //巡检列表页面
    {
        path: "/:project_id/inspection-list",
        name: "index-inspection-list",
        component: () =>
            import("@/views/front/common/inspection-list/InspectionList.vue"),
    },
    //微服务列表页面
    {
        path: "/:project_id/agent-list",
        name: "index-agent-list",
        component: () => import("@/views/front/common/agent-list/AgentList.vue"),
    },
    //故障详情页面
    {
        path: "/agent-detail",
        name: "index-agent-detail",
        component: () => import("@/views/front/common/agent-list/AgentDetail2"),
    },
    //风险事件页面
    {
        path: "/:project_id/risk",
        name: "index-diagnosis-risk",
        // component: () => import("@/views/front/cabr/diagnosis/risk"),
        component: () => import("@/views/front/cabr/diagnosis/calendar"),
    },
    //事件日历页面
    {
        path: "/calendar",
        name: "index-diagnosis-calendar",
        component: () => import("@/views/front/cabr/diagnosis/calendar"),
    },
    //故障分析页面
    {
        path: "/:project_id/agent-analyse",
        name: "index-agent-analyse",
        component: () => import("@/views/front/common/agent-list/AgentAnalyse.vue"),
    },
    //警报列表页面
    {
        path: "/:project_id/alarm-list",
        name: "index-alarm-list",
        component: () => import("@/views/front/common/alarm-list/AlarmList.vue"),
    },
    //iframe页面
    {
        path: "/:project_id/iframe/:src",
        name: "index-project-iframe",
        component: () => import("@/views/front/common/iframe/Iframe.vue"),
    },
    // kpi分析页面
    {
        path: "/:project_id/kpi-analyse",
        name: "index-project-kpi-analyse",
        component: () => import("@/views/front/common/agent-list/components/FunMenu/KpiAnalyse"),
    },
    // 报警管理
    {
        path: "/:project_id/alarm",
        name: "index-diagnosis-alarm",
        component: () => import("@/views/front/cabr/diagnosis/alarm"),
    },
];
