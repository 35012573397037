import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;


export const getNotice = () => axios.get('/api/diagnosis/notice/')


export const detail_get_type = (params) => axios.get('/api/diagnosis/detail/detail_get_type', {params})


export const detail_get_degree = (params) => axios.get('/api/diagnosis/detail/detail_get_degree', {params})
export const detail_get_results = (params) => axios.get('/api/diagnosis/detail/detail_get_results', {params})


export const data_relationship = (params) => axios.get('/api/diagnosis/relationship', {params})

export const fault_detail = (id) => axios.get(`/api/diagnosis/fault/detail/${id}`)


export const fault_risk = (params) => axios.get(`/api/diagnosis/fault/risk`, {params})

export const professional_list = (params) => axios.get('/api/diagnosis/professional/', {params});

export const general_list = (params) => axios.get('/api/diagnosis/general/', {params});

export const getDiagnosisOptions = (params) => axios.get("/api/diagnosis/options", {params});

export const getDiagnosisTraceabilityGetDeviceInfo = (params) => axios.get("/api/diagnosis/traceability_get_device_info", {params});

export const getDiagnosisTraceabilityGetDeviceDiagnosisForParams = (params) => axios.get("/api/diagnosis/traceability_get_device_diagnosis_for_params", {params});

export const getDiagnosisAllDevices = (params) => axios.get("/api/diagnosis/get_all_devices", {params});
export const getDiagnosisRiskRecordInfo = (params) => axios.get("/api/diagnosis/get_risk_record_info", {params});
export const getDiagnosisRiskStatics = (params) => axios.get("/api/diagnosis/risk_statistics", {params});
export const getDiagnosisRiskDevices = () => axios.get("/api/diagnosis/get_risk_devices");
export const getDiagnosisGetRiskHeatmap = () => axios.get("/api/diagnosis/get_risk_heatmap");
export const getDiagnosisHandleEvent = (data) => axios.post("/api/diagnosis/handle_event", data);
export const getDiagnosisDetailSearchOptions = (data) => axios.post("/api/diagnosis/detail_search_options", data);
export const getDiagnosisAlertList = () => axios.get("/api/diagnosis/fault/alert_list");
export const getDiagnosisCalendar = (params) => axios.get("/api/diagnosis/fault/calendar", {params});
export const getDiagnosisSendWechatNotice = (data) => axios.post("/api/diagnosis/fault/send_wechat_notice", data);
export const getDiagnosisDisposalRate = () => axios.get("/api/diagnosis/fault/disposal_rate");
